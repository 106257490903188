import React, { useEffect } from 'react';

const MailerLiteForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,e,u,f,l,n){
        w[f]=w[f]||function(){
          (w[f].q=w[f].q||[]).push(arguments);
        },
        l=d.createElement(e),
        l.async=1,
        l.src=u,
        n=d.getElementsByTagName(e)[0],
        n.parentNode.insertBefore(l,n);
      })(window,document,'script','https://assets.mailerlite.com/js/universal.js','ml');
      ml('account', '1054992'); // Replace '1054992' with your actual account ID
    `;
    document.body.appendChild(script);
  }, []);

  return (
    <div className="ml-embedded" data-form="mRcViT"></div>
  );
};

export default MailerLiteForm;
