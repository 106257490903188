import React from "react";
import logolight from "../assets/images/logo-light.png";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

const ListCard = (props) => {
  return (
    <React.Fragment>
      <div className={props.isList ? "col-lg-3 col-md-6" : "col-lg-2 col-md-6"}>
        <div className={props.isShow ? "mt-4 ps-0 ps-lg-5" : "mt-4"}>
          <h6 className="text-white text-uppercase fs-16">{props.title}</h6>
          <ul className="list-unstyled footer-link mt-3 mb-0">
            {props.isChildList.map((Item, key) => (
              <li key={key}>
                <Link to="/#" alt="work">
                  {Item.subList}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
}

const ListData = [
  {
    title: "Resources",
    isList: true,
    isShow: true,
    isChildList: [
      { id: 1, subList: "Terms & Service" },
      { id: 2, subList: "Privacy Policy" },
    ],
  },
  {
    title: "Help",
    isList: false,
    isShow: false,
    isChildList: [
      { id: 1, subList: "Documentation" },
      { id: 2, subList: "FAQs" },
    ],
  },
];

const ListIconData = [
  {
    iconClass: "mdi mdi-linkedin",
    link: "https://www.linkedin.com/company/ascendon-io"
  }
];

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="bg-footer">
        <Container>
          <Row className="justify-content-between">
            <Col lg={3}>
              <div className="mt-4">
                <Link to="/">
                  <img src={logolight} alt="" height="22" />
                </Link>
                <p className="text-white-50 mt-3 pt-2 mb-0 ">
                  Catch up with us on social media
                </p>
                <div className="mt-4">
                  <ul className="list-inline footer-social">
                    {/* Footer Icon Component Start */}
                    {ListIconData.map((item, key) => (
                      <li className="list-inline-item" key={key}>
                        <Link to={item.link}>
                          <i className={item.iconClass}></i>
                        </Link>
                      </li>
                    ))}
                    {/* Footer Icon Component End */}
                  </ul>
                </div>
              </div>
            </Col>

            {/* Footer List Start*/}

            {ListData.map((item, key) => (
              <ListCard
                key={key}
                isList={item.isList}
                isShow={item.isShow}
                title={item.title}
                isChildList={item.isChildList}
              />
            ))}
            {/* Footer List End*/}
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
