import React from "react";
import { Col, Container, Row } from "reactstrap";

import MailerLiteForm from "./MailerLiteForm";

const CTA = () => {

  return (
    <React.Fragment>
      <section className="bg-cta">
        <Container>
          <Row className="justify-content-center">
            <Col lg={10}>
              <div className="header-title text-center">
                <h2>Beta Program</h2>
                <p/>
                <MailerLiteForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default CTA;
