import React from "react";
import { Col, Container, Row } from "reactstrap";

import img01 from "../assets/images/feature/img-01.png";
import img02 from "../assets/images/feature/img-02.png";
import img03 from "../assets/images/feature/img-03.png";


const ProductsCard = (props) => {
  return (
    <>
      <Col lg={4} md={6}>
        <div
          className={
            props.isShadow
              ? "product-box text-center mt-4 box-shadow"
              : "product-box text-center mt-4"
          }
        >
          <img src={props.imgsrc} alt="" className="img-fluid" />
          <h5 className="fs-18 mt-4">{props.title}</h5>
          <div className="lighlight-border mt-3"></div>
          <p className="text-muted mt-3">{props.caption}</p>
        </div>
      </Col>
      {/* <!--end col--> */}
    </>
  );
}

// Products data

const ProductsData = [
  {
    imgsrc: img01,
    title: "Streamline Your Operations with Ease",
    caption:
    "Our Workflow Automation tool helps you eliminate repetitive tasks and automate routine processes like data entry, approvals, and scheduling. By integrating seamlessly with your existing systems, you can save time, reduce errors, and ensure consistency across your operations. Focus on what matters most while our automation takes care of the mundane tasks.",
  },
  {
    imgsrc: img02,
    title: "Boost Customer Engagement and Sales Efficiency",
    caption:
      "Our CRM Automation solution ensures you never miss a follow-up with your leads and customers. Automate your customer interactions, manage data seamlessly, and run targeted email campaigns to enhance engagement. Improve your sales processes with timely and personalized communication, turning prospects into loyal customers.",
      isShadow: true,
  },
  {
    imgsrc: img03,
    title: "Deliver Exceptional Customer Support 24/7",
    caption:
      "Enhance your customer service with our Customer Service Automation platform. Implement chatbots and automated support systems to handle inquiries, bookings, and issue resolution around the clock. Improve customer satisfaction with quick, reliable responses, and reduce the workload on your support staff."
  }
];

const Products = () => {
  return (
    <>
      <section className="section" id="product">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}> 
              <div className="header-title text-center">
                <p className="text-uppercase text-muted mb-2">
                  intelligent automation
                </p>
                <h3>Add instance AI intelligence to your business</h3>
                <div className="title-border mt-3"></div>
                <p className="text-muted mt-3">
                  Let ascendon's low code technology, AI agents and models power the next revolution
                  in your businesses processes. We provide the tools and the platform to help you innovate
                  using your existing data and systems.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            {/* Products Component Start */}

            {ProductsData.map((value, idx) => (
              <ProductsCard
                key={idx}
                imgsrc={value.imgsrc}
                title={value.title}
                caption={value.caption}
                isShadow={value.isShadow}
              />
            ))}

            {/* Products Component End */}
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Products;
