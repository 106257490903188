import React from "react";
import { Col, Container, Row } from "reactstrap";

const IntegrationCard = (props) => {
  return (
    <React.Fragment>
      <Col lg={3} md={6}>
        <div className={props.number !== "04" ? "integration-box integration-border text-center" : "integration-box text-center"}>
          <div className="integration-count text-white mt-4">
            <h3 className="integration-number">{props.imgSrc}</h3>
          </div>

          <div className="integration-content bg-light mt-5 rounded p-4">
            <h5 className="fs-18">{props.title}</h5>
            <p className="text-muted mb-0">{props.caption}</p>
          </div>
        </div>
        {/* <!--end integration-box--> */}
      </Col>
    </React.Fragment>
  );
}

// Integration Data

const IntegrationData = [
  {
    imgSrc: "01",
    title: "Hubspot",
    caption: "Syncing customer data with other systems, automating marketing campaigns, and integrating with customer support tools.",
  },
  {
    imgSrc: "02",
    title: "Stripe",
    caption: "Streamline payment processing, integrating with e-commerce platforms, and managing subscription billing",
  },
  {
    imgSrc: "03",
    title: "Quickbooks",
    caption: "Automating bookkeeping, creating custom financial reports, and integrating with e-commerce platforms.",
  },
  {
    imgSrc: "04",
    title: "Salesforce",
    caption: "Automating sales processes, integrating with marketing tools, and syncing customer data.",
  },
  {
    imgSrc: "05",
    title: "Xero",
    caption: "Automating financial reporting, syncing accounting data with CRM, and automating payroll processing.",
  },
  {
    imgSrc: "06",
    title: "BambooHR",
    caption: "Integrating and straemlining your busuness HR processes.",
  },
  {
    imgSrc: "07",
    title: "Google Workspace",
    caption: "Automating email marketing, syncing calendar events with CRM, and managing documents programmatically.",
  },
  {
    imgSrc: "08",
    title: "Mailchimp",
    caption: "Automating email campaigns, syncing customer data, and integrating with e-commerce platforms.",
  },
  {
    imgSrc: "09",
    title: "Shopify",
    caption: "Automating order processing, inventory management, and integrating with third-party logistics.",
  },
  {
    imgSrc: "09",
    title: "Others added all the time",
    caption: "We listen to our customers and add new integrations regularly.",
  },
];

const Integrations = () => {
  return (
    <React.Fragment>
      <section className="bg-integration section" id="integration">
        <Container>
          <Row className="justify-content-center">
            <Col lg={7}>
              <div className="header-title text-center">
                <h3>Our Integrations</h3>
                <div className="title-border mt-3"></div>
                <p className="title-desc text-muted mt-3">
                  We provide out of the box connections to your favorite business tools, allowing us to integrate with your workflow. If you don't see a tool you use, let us know and we can build a custom integration for you.
                </p>
              </div>
            </Col>
          </Row>

          <Row className="mt-5 pt-2">
            {/* Integration Component Start */}

            {IntegrationData.map((value, idx) => (
              <IntegrationCard
                key={idx}
                imgSrc={value.imgSrc}
                title={value.title}
                caption={value.caption}
                isShow={value.isShow}
              />
            ))}

            {/* Integration Component End */}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
}

export default Integrations;
